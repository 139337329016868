<template
  ><div>
    <v-form @submit.prevent="submit" v-model="isValid" ref="form">
      <pop-up-layout title="Add Task">
        <v-text-field
          v-bind="basic"
          class="mt-6"
          label="Title"
          v-model="data.title"
          outlined
        ></v-text-field>

        <v-textarea
          v-bind="basic"
          class="my-5"
          outlined
          name="input-7-4"
          label="Description"
          v-model="data.description"
        ></v-textarea>

        <v-menu
          ref="menu"
          v-model="menu1"
          :close-on-content-click="false"
          :return-value.sync="date"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              class="mt-5"
              v-model="data.start_date"
              label="Start Date"
              append-icon="mdi-calendar"
              readonly
              v-bind="{ ...attrs, ...basic }"
              v-on="on"
              outlined
              :rules="[rules.required]"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="data.start_date"
            no-title
            scrollable
            @change="menu1 = false"
          />
        </v-menu>

        <v-menu
          ref="menu"
          v-model="menu2"
          :close-on-content-click="false"
          :return-value.sync="date"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              class="mt-5"
              v-model="data.end_date"
              label="End Date"
              append-icon="mdi-calendar"
              readonly
              v-bind="{ ...attrs, ...basic }"
              v-on="on"
              outlined
              :rules="[rules.required]"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="data.end_date"
            no-title
            scrollable
            @change="menu2 = false"
          />
        </v-menu>

        <v-combobox
          v-bind="basic"
          class="mt-5"
          v-model="data.users"
          :items="users"
          :rules="[rules.required]"
          item-text="full_name"
          item-value="id"
          label="Assign Users"
          multiple
          chips
          outlined
        >
          <template v-slot:selection="data">
            <v-chip
              :key="JSON.stringify(data.item)"
              v-bind="data.attrs"
              :input-value="data.selected"
              :disabled="data.disabled"
              @click:close="data.parent.selectItem(data.item)"
            >
              {{ data.item.full_name }}
            </v-chip>
          </template>
        </v-combobox>

        <v-select
          v-bind="basic"
          class="mt-5"
          v-model="data.priority"
          :items="priorityOptions"
          label="Priority"
          chips
          :rules="[rules.required]"
          outlined
        />

        <v-select
          v-bind="basic"
          class="mt-5"
          v-model="data.status"
          :items="statusOptions"
          label="Status"
          :rules="[rules.required]"
          chips
          outlined
        />

        <v-col>
          <div class="mt-5 d-flex justify-center">
            <v-btn
              elevation="2"
              v-bind="basic"
              class="col-5 mr-4 submit-button"
              type="submit"
              :disabled="false"
              :loading="loading"
            >
              Save
            </v-btn>
            <v-btn class="col-5 cancel" @click="cancel"> Cancel </v-btn>
          </div>
        </v-col>
      </pop-up-layout>
    </v-form>
  </div>
</template>

<script>
import util from "@/util/util";
import PopUpLayout from "@/components/PopUpLayout.vue";

export default {
  components: { PopUpLayout },
  props: ["statusName", "boardId", "parentId"],
  data() {
    return {
      loading: false,
      data: {
        title: "",
        description: "",
        status: "",
        start_date: "",
        end_date: "",
        priority: "",
        users: [],
      },
      statusOptions: [],
      users: [],
      priorityOptions: util.priorityOptions,
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      menu1: false,
      modal: false,
      menu2: false,
      isValid: false,
      rules: util.validation,
      basic: util.input.basic,
    };
  },
  mounted() {
    this.fetchUsers();
    this.fetchStatusOptions();
  },
  methods: {
    fetchStatusOptions() {
      util.http({
        url: 'filters/tm-task',
        method: 'get',
      }).then(resp => {
        this.statusOptions = resp.data.data;
      });
    },
    submit() {
      this.loading = true;
      this.data.board_id = this.$props.boardId;
      if (this.$props.parentId) {
        this.data.parent_id = this.$props.parentId;
      } else {
        this.data.parent_id = null;
      }
      if (this.data.users.length > 0) {
        this.data.assignees = this.data.users.map((u) => {
          return {
            user_id: u.id,
            start_date: this.data.start_date,
            end_date: this.data.end_date,
            project_role_id: 1,
          };
        });
      } else {
        this.data.assignees = [];
      }
      let url = "/tm/task";
      util
        .http({
          url: url,
          method: "post",
          data: this.data,
        })
        .then((res) => {
          this.loading = false;
          if (res.data.status == 1) {
            util.notify(1, "Task Created");
            this.$refs.form.reset();
            this.data = {
              assignees: null,
              status: null,
              priority: null,
              name: "",
            };
            this.$emit("reloadResource", true);
          } else {
            util.notify(0, res.data.errors[0]);
          }
        })
        .catch();
    },
    fetchUsers() {
      util
        .http({
          url: "/user/get-all",
        })
        .then((res) => {
          if (res.data.status == 1) {
            this.users = res.data.data;
          } else {
            util.notify(0, res.data.errors[0]);
          }
        })
        .catch();
    },
    cancel() {
      this.$emit("cancel", true);
    },
  },
};
</script>
<style scoped>
.submit-button {
  background: #1976d2;
  border-color: #1976d2;
}

.cancel {
  border-color: #1976d2;
}
</style>
